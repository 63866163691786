<template>
  <div class="flex-bet-cen shop-item__container">
    <div class="shop__container-left flex">
      <SmallImg :image="data.image" :styleType="data.styleType" />
      <div class="shop__container-info">
        <div class="shop__container-title font-medium">
          <!-- {{ $trans(`goods-${data.spuId}.title`, data.title) || data.spuTitle }} -->
          {{
            $trans(`goods-${data.spu}.title`, data.title) ||
            $trans(`goods-${data.spuId}.title`, data.spuTitle)
          }}
        </div>
        <div class="shop__container-author">
          「{{ $trans(`artist-${data.artistId}.name`, data.artistName) }}」
        </div>
      </div>
    </div>
    <div class="shop__container-right flex-column-end">
      <div class="shop__container-number">×{{ data.count }}</div>
      <div class="object__price">
        <div style="font-style: normal">￥</div>
        <div class="object__int">{{ data.price | product_int }}</div>
        <div class="object__float">{{ data.price | product_float }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import SmallImg from '@/components/img/Small.vue'
export default {
  name: '',
  props: {
    data: {
      typeof: Object,
    },
  },
  components: {
    SmallImg,
  },
}
</script>
<style scoped>
.shop-item__container {
  margin-bottom: 20px;
}
.shop__container-left {
  align-items: flex-start;
}
.shop__container-info {
  margin-left: 30px;
}
.shop__container-title {
  font-size: 16px;
  margin-bottom: 10px;
}
.shop__container-author {
  font-size: 12px;
  transform: translateX(-5px);
  color: #595959;
}
.shop__container-number {
  width: 24px;
  height: 24px;
  background: #dfdfdf;
  border-radius: 2px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  margin-right: 7px;
  margin-bottom: 4px;
}
</style>
