<template>
  <div class="line-bottom address__component" @click="selectAddressHandle">
    <div class="address__item-top font-medium flex-bet-cen">
      <div class="flex-column">
        <span class="address__item-name">{{ data.contactName }}</span>
        <span class="address__item-tel">{{ data.contactPhone }}</span>
      </div>
      <div @click="enterPageHandle($event, data)">
        <i class="iconfont icon-shouhuodizhibianji"></i>
      </div>
    </div>
    <div class="address__item-detail font-regular text-over-2">
      {{ data.addrFull }}
    </div>
    <div class="address__manage flex-cen" v-if="edit" @click="stopProHandle">
      <div class="address__delete">
        <el-popconfirm
          confirm-button-text="好的"
          cancel-button-text="取消"
          icon="el-icon-info"
          icon-color="red"
          @confirm="deleteAddressHandle"
          title="确定要删除这一条数据吗？"
        >
          <!-- <span slot="reference">删除地址</span> -->
          <span class="iconfont icon-shanchu" slot="reference"></span>
        </el-popconfirm>
      </div>
      <div class="default-address flex-cen">
        <el-switch
          v-model="data.isDefault"
          :active-text="$trans('默认地址', '默认地址')"
          active-color="#13ce66"
          inactive-color="#666666"
          @change="editAddressHandle"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>
<script>
import { editAddress, deleteAddress } from '@/util/js/api.js'
export default {
  name: '',
  props: {
    data: {
      // 数据
      typeof: Object,
    },
    edit: {
      // 是否可以修改默认地址或者删除
      typeof: Object,
      default: false,
    },
    onlyshow: {
      typeof: Boolean,
      default: false,
    },
  },
  methods: {
    // 选择地址
    selectAddressHandle() {
      if (this.onlyshow) {
        return
      }
      if (this.$route.path != '/address/index') {
        this.$router.history.push({
          path: '/address/index',
          query: {
            ...this.$route.query,
            type: 'edit',
            address: JSON.stringify(this.data),
            path: this.$route.path,
          },
        })
      } else {
        this.$router.history.push({
          path: this.$route.query.path,
          query: {
            ...this.$route.query,
            address: JSON.stringify(this.data),
          },
        })
      }
    },
    // 进入编辑页面地址
    enterPageHandle(e, data) {
      if (this.onlyshow) {
        return
      }
      console.log(JSON.stringify(data))
      e.stopPropagation()
      this.$router.history.push({
        path: '/address/new',
        query: {
          ...this.$route.query,
          address: JSON.stringify(data),
          pathEdit: this.$route.path,
        },
      })
    },
    // 触发修改默认地址的请求
    editAddressHandle() {
      let params = this.data
      editAddress(params)
        .then((res) => {
          if (res.code == '200') {
            this.$message.success(this.$trans('修改成功', '修改成功'))
            this.$emit('on')
          } else {
            this.$message.warning(
              this.$trans('修改失败,请稍后再试', '修改失败,请稍后再试')
            )
          }
        })
        .catch(() => {
          this.$message.error(
            this.$trans('修改失败,请稍后再试', '修改失败,请稍后再试')
          )
        })
    },
    // 触发删除地址的请求
    deleteAddressHandle() {
      let param = {
        addrId: this.data.id,
        userId: this.$store.state.userId,
      }
      deleteAddress(param)
        .then((res) => {
          if (res.code == '200') {
            this.$message.success(this.$trans('删除成功', '删除成功'))
            this.$emit('on')
          } else {
            this.$message.warning(
              this.$trans('删除失败，请稍后再试', '删除失败，请稍后再试')
            )
          }
        })
        .catch(() => {
          this.$message.error(
            this.$trans('修改失败,请稍后再试', '修改失败,请稍后再试')
          )
        })
    },
    // 阻止冒泡
    stopProHandle(e) {
      e.stopPropagation()
    },
  },
}
</script>
<style scoped>
.address__component {
  cursor: pointer;
}
.address__item-top {
  font-size: 16px;
  line-height: 30px;
}
.address__item-tel {
  font-size: 14px;
  margin-bottom: 5px;
}
.address__item-detail {
  color: #444444;
  line-height: 30px;
  font-size: 14px;
}
.icon-shouhuodizhibianji {
  font-size: 20px;
  cursor: pointer;
}
.address__manage {
  justify-content: flex-end;
}
.address__manage > div {
  margin-right: 20px;
  cursor: pointer;
}
.icon-shanchu {
  font-size: 20px;
}
</style>
