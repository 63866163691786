<template>
  <div
    @click.stop="skipHandle"
    class="img-small__component flex-cen-cen"
    :style="{ width: boxWidth + 'px', height: boxHeight + 'px' }"
  >
    <div :class="[styleType == 0 ? '' : 'border-iframe-outer']">
      <div :class="[styleType == 0 ? '' : 'border-iframe-inner']">
        <img
          src=""
          v-lazy="image"
          alt=""
          :style="{
            'max-width': maxWidth + 'px',
            'max-height': maxHeight + 'px',
          }"
        />
      </div>
    </div>
    <div v-if="layer" class="layer-outer flex-cen-cen">
      <span>{{ layerText }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    image: {
      // 图片地址
      typeof: String,
      default: '',
    },
    boxWidth: {
      // 盒子宽度
      typeof: Number,
      default: 60,
    },
    boxHeight: {
      // 盒子高度
      typeof: Number,
      default: 70,
    },
    maxWidth: {
      // 图片最多宽度
      typeof: Number,
      default: 40,
    },
    maxHeight: {
      // 图片最大高度
      typeof: Number,
      default: 40,
    },
    layer: {
      typeof: Boolean,
      default: false,
    },
    layerText: {
      typeof: String,
      default: '已售磬',
    },
    skip: {
      typeof: Boolean,
      default: false,
    },
    id: {
      typeof: String || Number,
    },
    styleType: {
      typeof: String,
    },
  },
  methods: {
    skipHandle() {
      if (this.skip) {
        this.$router.history.push({
          path: '/shop/detail/' + this.id,
        })
      }
    },
  },
  mounted() {},
}
</script>
<style scoped>
.img-small__component {
  background: #f4f4f4;
  border-radius: 4px;
  flex-shrink: 0;
  position: relative;
}
.img-small__component .border-iframe-outer {
  display: inline-block;
  border: 1px solid #111111;
  transition: all 0.5s;
  box-shadow: 3px 3px 3px #999999;
}
.img-small__component .border-iframe-inner {
  border: 1px solid #ffffff;
}
.img-small__component img {
  width: auto;
  height: auto;
  display: block;
}
.img-small__component .layer-outer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
</style>
