<template>
  <el-dialog
    :close-on-click-modal="true"
    class="wx-payment__component flex-cen-cen"
    :visible.sync="wxDialogVisible"
    :before-close="handleClose"
  >
    <div class="flex-cen">
      <div class="flex-column-cen">
        <div id="qrcode1" ref="qrcode1"></div>
        <div class="wram-tip flex-bet-cen">
          <i class="iconfont icon-saoyisao"></i>
          <span>请用微信二维码扫描已完成支付</span>
        </div>
      </div>
      <img class="wx-payment-img" src="@/assets/img/payment.png" alt="" />
    </div>
  </el-dialog>
</template>
<script>
import QRCode from 'qrcodejs2'
import { getOrderPay, paymentStateApi, closeAccount } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      wxDialogVisible: false, // 弹出层
      QRCodeUrl: '', // 微信二维码地址
      batchNoFirst: '', // 第一次支付的返回值
    }
  },
  props: {
    batchNo: {
      // 订单码
      typeof: String,
    },
  },
  methods: {
    /**
     * 支付操作
     * params :{
     *      batchNo:订单批次
     *      payType:支付方式    2:支付宝    1：微信
     *      addressId:地址ID
     *  }
     * 或者
     * params : {
     *      addressId:地址Id
     *      orderToken:防重令牌
     *      payPrice:需要支付的金额
     *      payType:支付方式    2：支付宝   1：微信
     *      userId:用户ID
     *      goodsNumberMap:{
     *          商品skuId:数量count
     *      }
     *  }
     * */
    paymentHandle(params) {
      if (params.batchNo) {
        this.batchNoPayHandle(params)
      } else if (params.orderToken) {
        this.infoPayHandle(params)
      }
    },
    // 订单信息支付
    infoPayHandle(params) {
      closeAccount(params)
        .then(
          (res) => {
            if (res.code && (res.code == 500 || res.code == 10000)) {
              this.$message.error(res.msg ? res.msg : '服务器错误')
              this.$router.history.push('/')
            } else {
              if (params.payType == 2) {
                document.querySelector('body').innerHTML = res.url
                document.forms[0].submit()
              } else {
                this.QRCodeUrl = res.url
                this.wxDialogVisible = true
                this.batchNoFirst = res.batchNo
                this.$nextTick(() => {
                  document.querySelector('#qrcode1').innerHTML = ''
                  this.decidePaymentState(res.batchNo)
                  return new QRCode(this.$refs.qrcode1, {
                    width: 200, // 宽
                    height: 200, // 高
                    text: this.QRCodeUrl, // 生成二维码的内容
                    render: 'canvas', // 设置渲染方式（canvas,table 默认canvas）
                    correctLevel: QRCode.CorrectLevel.L, // 二维码纠错级别（L,M,Q,H）
                  })
                })
              }
            }
          },
          (err) => {
            this.$message.warning(err.message)
            this.$router.history.push('/')
          }
        )
        .catch((err) => {
          this.$message.error(err.message ? err.message : err)
          this.$router.history.push('/')
        })
    },
    // 订单支付
    batchNoPayHandle(params) {
      getOrderPay(params)
        .then(
          (res) => {
            if (res.code && (res.code == 500 || res.code == 10000)) {
              this.$message.error(res.msg ? res.msg : '服务器错误')
              this.$router.history.push('/')
            } else if (res.code == 20002 || res.msg == '订单支付失败') {
              this.$message.error(
                this.$trans('订单已超时自动取消', '订单已超时自动取消')
              )
              this.wxDialogVisible = false
              this.$router.history.push('/')
            } else {
              // 微信支付
              if (params.payType == 1) {
                this.QRCodeUrl = res.url
                this.wxDialogVisible = true
                this.$nextTick(() => {
                  document.querySelector('#qrcode1').innerHTML = ''
                  this.decidePaymentState(this.batchNo)
                  return new QRCode(this.$refs.qrcode1, {
                    width: 200, // 宽
                    height: 200, // 高
                    text: this.QRCodeUrl, // 生成二维码的内容
                    render: 'canvas', // 设置渲染方式（canvas,table 默认canvas）
                    correctLevel: QRCode.CorrectLevel.L, // 二维码纠错级别（L,M,Q,H）
                  })
                })
                // 支付宝支付
              } else {
                document.querySelector('body').innerHTML = res.url
                document.forms[0].submit()
              }
            }
          },
          (err) => {
            this.$message.warning(err.message)
            this.$router.history.push('/')
          }
        )
        .catch((err) => {
          this.$message.warning(err.message)
          this.$router.history.push('/')
        })
    },
    // 判断微信支付是否完成
    decidePaymentState(batchNo) {
      setTimeout(() => {
        debugger
        if (this.wxDialogVisible) {
          paymentStateApi({ batchNo }).then((res) => {
            if (res.code == '200') {
              this.wxDialogVisible = false
              this.$message.success(this.$trans('支付成功', '支付成功'))
              this.$router.history.push('/')
            } else {
              return
            }
          })
          this.decidePaymentState(batchNo)
        }
      }, 3000)
    },
    // 微信支付弹窗关闭之前
    handleClose() {
      if (this.wxDialogVisible) {
        this.$message.error(this.$trans('支付失败', '支付失败'))
        this.wxDialogVisible = false
        if (this.$route.path != '/order/index/unpaid/detail') {
          this.$router.history.push({
            path: '/order/index/unpaid/detail',
            query: {
              ...this.$route.query,
              batchNo: this.batchNoFirst,
            },
          })
        }
      } else {
        this.$message.success(this.$trans('支付成功', '支付成功'))
        this.$router.history.push('/')
      }
    },
  },
}
</script>
<style>
.wx-payment__component .el-dialog__body .wx-payment-img {
  width: 300px;
  height: auto;
  margin-left: 60px;
  max-width: unset;
  max-height: unset;
}
.wx-payment__component .el-dialog {
  width: unset;
  display: inline-block;
  padding: 20px;
}
.wx-payment__component .wram-tip {
  width: 240px;
  border: 2px solid #111111;
  border-radius: 4px;
  padding: 15px;
  margin-top: 40px;
  line-height: 30px;
  font-size: 16px;
}
.wx-payment__component .wram-tip .icon-saoyisao {
  font-size: 50px;
  margin-right: 15px;
}
</style>
